.LandingSection{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.landing_container{
    width: var(--container-width-lg);
    height: 84%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    align-content: flex-end;
}

.landing_box{
    width: 70vw;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.landing_logo {
    width: 6.7rem;
}

.logo_head {
    text-align: center;
    line-height: 1.7rem;
    margin: 6px;
    color: var(--color-hover-gray);
}

.logo_head h1 {
    font-size: 2rem;
    font-weight: 600;
    padding-top: 6px;
}

.logo_head h4 {
    text-align: center;
    font-size: 0.85rem;
    font-weight: 300;
    padding: 0;
    margin: 0;
    letter-spacing: 3px;
}

.main_heading {
    font-size: 4rem;
    color: var(--color-primary-white);
    /* box-sizing: border-box;
    border-left: 1.2rem solid var(--color-primary);
    padding-left: 1rem; */
    text-shadow: 0px 0px 10px rgb(0 0 0 / 50%);
}

.head_tag {
    color: var(--color-primary-head);
    font-size: 1rem;
    font-weight: 10;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 50%);

}

.head_tag span {
    color: var(--color-primary);
    font-weight: 600;
}

.head_tag small {
    color: var(--color-primary-head);
    font-weight: 400;
    font-size: 1.1rem;
}


.landing_btn {
    padding: 1.3rem;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 0.7rem;
}


.btn-primary {
    font-size: 1rem;
}




.wave_container{
    padding: 0;
    width: 100%;
    height: 16%;
    position: relative;
}

#waveSection{
    width: 100%;
    height: 100%;
}

#waveSection .wave {
    position: absolute;
    background: url(../media/images/wave.png);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5.4rem;
    background-size: 100% 5.4rem;
}

#waveSection .wave.wave1{
    animation: animate 15s linear infinite;
    z-index: 6;
    animation-delay: 0s;
    bottom: 0;
}

#waveSection .wave.wave2{
    animation: animate2 8s linear infinite;
    z-index: 5;
    opacity: 0.5;
    animation-delay: -10s;
    bottom: 10px;
}

#waveSection .wave.wave3{
    animation: animate2 8s linear infinite;
    z-index: 4;
    opacity: 0.3;
    animation-delay: -8s;
    bottom: 15px;
}

#waveSection .wave.wave4{
    animation: animate2 6s linear infinite;
    z-index: 3;
    opacity: 0.2;
    animation-delay: -5s;
    bottom: 20px;
}


/* Animation Keyframe */

@keyframes animate {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 4000px;
    }
}

@keyframes animate2 {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 4000px;
    }

}

@keyframes animate3 {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 4000px;
    }

}

@keyframes animate4 {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 4000px;
    }

}




.info_sec{
    width: 100%;
    height: fit-content;
    padding: 1.3rem 0 2.7rem;
    /* background-color: var(--color-primary-head); */
    background-color: var(--color-primary-white) ;
}

.info_container {
    width: var(--container-width-lg);
    /* background-color: aquamarine; */
    height: 100%;    
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.info_top {
    display: flex;
    justify-content: center;
    align-items: center;
}

.info_head {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
}

.info_img{
    display: flex;
    justify-content: center;
    align-items: center;
}

.info_img img{
    width: 200px;
    height: 250px;
    border-radius: 24px;
}


.Schedule_container{
    width: 100%;
    height: fit-content;
    background-color: var(--color-primary-white);
    /* -webkit-box-shadow: inset -3px -32px 300px -80px rgba(0,0,0,0.54);
    -moz-box-shadow: inset -3px -32px 300px -80px rgba(0,0,0,0.54);
    box-shadow: inset -3px -32px 300px -80px rgba(0,0,0,0.54); */
    display: flex;
}

.page_container {
    width: 100%;
    height: 100vh;
    background-color: var(--color-hover-gray);
    display: flex;
}

.campsite_section {
    width: 100%;
    height: 80vh;
    background-color: var(--color-primary-white);
}

.campsite_main {
    position: relative;
    width: 100%;
    padding: 2rem 0;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    overflow-x: hidden;
    scroll-behavior:smooth;
}

.carousel-nav-btn-div {
    position: absolute;
    top: 40%;
    z-index: 9999;
    width: calc(100% + 3rem);
    left: -1.5rem;
    height: fit-content;
    display: flex;
    justify-content: space-between;
}

.carousel-nav-btn {
    width: 3rem;
    height: 3rem;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
    font-weight: bold;
    border-radius: 1.5rem;
    color: var(--color-primary);
    cursor: pointer;
    transition: all 0.4s ease;
}

.carousel-nav-btn:hover {
    opacity: 0.9;
}





.camp_card_container {
    min-width: 222px;
    height: 60vh;
    display: flex;
    flex-direction: column;
}


.site_card {
    min-width: 222px;
    height: 300px;
    background-color: var(--color-primary-white);
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.camp_card_img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* transition: var(--transition); */
    transition: transform 0.75s;

}

.site_card::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0px -70px 21px -14px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: inset 0px -70px 21px -14px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: inset 0px -70px 21px -14px rgba(0, 0, 0, 0.6);
    z-index: 1;
}


.camp_card_container:hover .camp_card_img {
    transform: scale(1.05);
    opacity: 1;
}

.card_rating_div {
    width: 100%;
    position: absolute;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    z-index: 2;
    padding: 0 10px;
    font-size: 15px;
}

.card_rating_div span {
    padding-left: 6px;
    color: white;
    font-weight: lighter;
    font-size: 10px;
    color: var(--color-hover-gray);
}

.rating_star {
    color:  #FFC300;
    display: flex;
    align-items: center;
}

.fev_heart {
    color: #E62851;
}

.card_footer {
    width: 100%;
    height: 80px;
    text-align: center;
    padding: 6px;
}

.card_footer h4 {
    font-size: 17px;
    font-weight: 600;
    color:var(--color-primary-head);
}

.card_footer_sec {
    display: flex;
    justify-content: center;
    gap: 0.8rem;
    padding-top: 0.5rem;
    align-items: center;
    font-size: 12px;
}


.btn-booking {
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 6px;
}

.card_max_price {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.card_max_price span {
    font-size: 12px;
    text-decoration: line-through;
}

.card_max_price p {
    font-size: 16px;
    font-weight: 600;
}

.camp_photo_section {
    width: 100%;
    height: 100vh;
    background-color: var(--color-primary-white);
}

.camp_photo_container {
    height: 100%;
}


.photo_sec_container {
    width: 100%;
    height: 90%;
    justify-self: start;
}

.photo_sec_inner {
    width: 100%;
    height: 90%;
    display: flex;
    gap: 12px;
}

.photo_grid {
    height: 100%;
    width: 42%;
    display: grid;
    grid-template-columns:  32% 32% 32%;
    grid-template-rows: auto auto auto auto;
    gap: 10px;
    color: white;
}

.photo_grid > div {
    border-radius : 12px;
}

.relative {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.last_grid {
    display: flex;
    text-align: center;
    height: 84%;
    border-radius: 12px;
}

.grid_btn {
    height: auto;
    display: flex;
    align-items: flex-end ;
}

.btn-gallery {
    width: 100%;
    padding: 6px;
    border-radius : 6px;
    margin-top: 12px;
}

.link_btn {
    color: white;
}




.grid_img {
    height: 40%;
}

.relative:hover .camp_card_img {
    transform: scale(1.05);
    opacity: 1;
}

.photo_grid_1 {
    grid-row-start: 1;
    grid-row-end: 3;
}

.photo_grid_2 {
    grid-column-start: 2;
    grid-column-end: 4;
}

.photo_grid_3 {
    grid-row-start: 2;
    grid-row-end: 4;
}
.photo_grid_4 {

    grid-row-start: 2;
    grid-row-end: 3;

}
.photo_grid_5 {

}
.photo_grid_6 {

    grid-row-start: 3;
    grid-row-end: 5;
    grid-column-start: 3;
    grid-column-end: 4;
    
}
.photo_grid_7 {

}
.photo_grid_8 {

}

.photo_banner {
    height: 100%;
    width: 58%;
    background-color: #FFC300;
    border-radius: 12px;
}

.svg_container{
    width: 100%;
    height: fit-content; 
}




@media screen and (max-width : 600px) {


    .LandingSection{
        height: 85vh;
    }

    .landing_container {
        width: var(--container-width-sm);
        height: 100%;
    }
    
    .landing_box{
        width: 90%;
        height: 65vh;
    }

    .landing_logo{
        width: 30%;
    }

    .logo_head > h1 {
        font-size: 1.8rem;
    }

    .logo_head > h4 {
        font-size: 0.75rem;
    }

    .main_heading {
        font-size: 2.3rem;
        margin: 0.5rem 0 0.5rem 0;
        text-align: center;
    }
    
    .head_tag {
        font-size: 0.8rem;
        font-weight: 600;
        background-color: #ffff;
    }
    
    .head_tag span {
        /* color: var(--color-primary-head); */
        color: var(--color-primary);
        font-style: bold;
        font-weight: 700;
    }
    .head_tag small {
        font-size: 0.8rem;
        /* color: var(--color-primary); */
        font-style: bold;
        font-weight: 700;
    }

    .landing_btn {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 0.6rem;
        padding: 0;
        padding-top: 1rem;
    }

    .btn-primary {
        font-size: 0.7rem;
        padding: 8px;
        border-radius: 6px;
    }

    /* Wave */
    .wave_container{
        padding: 0;
        width: 100%;
        height: 10%;
        position: relative;
    }
    
    #waveSection{
        width: 100%;
        height: 100%;
    }
    
    #waveSection .wave {
        position: absolute;
        background: url(../media/images/wave.png);
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3rem;
        background-size: 100% 3rem;
    }
    
    #waveSection .wave.wave1{
        animation: animate 20s linear infinite;
        z-index: 6;
        animation-delay: 0s;
        bottom: 0;
    }
    
    #waveSection .wave.wave2{
        animation: animate2 13s linear infinite;
        z-index: 5;
        opacity: 0.5;
        animation-delay: -10s;
        bottom: 10px;
    }
    
    #waveSection .wave.wave3{
        animation: animate2 10s linear infinite;
        z-index: 4;
        opacity: 0.3;
        animation-delay: -8s;
        bottom: 15px;
    }
    
    #waveSection .wave.wave4{
        animation: animate2 8s linear infinite;
        z-index: 3;
        opacity: 0.2;
        animation-delay: -5s;
        bottom: 20px;
    }
    
    
    /* Animation Keyframe */
    
    @keyframes animate {
        0% {
            background-position-x: 0;
        }
        100% {
            background-position-x: 4000px;
        }
    }
    
    @keyframes animate2 {
        0% {
            background-position-x: 0;
        }
        100% {
            background-position-x: 4000px;
        }
    
    }
    
    @keyframes animate3 {
        0% {
            background-position-x: 0;
        }
        100% {
            background-position-x: 4000px;
        }
    
    }
    
    @keyframes animate4 {
        0% {
            background-position-x: 0;
        }
        100% {
            background-position-x: 4000px;
        }
    
    }
    

    /* Info Section */
    .info_sec{
        width: 100%;
        height: fit-content;
        padding: 1.3rem 0 2.7rem;
        /* background-color: var(--color-primary-head); */
        background-color: var(--color-primary-white) ;
    }
    
    .info_container {
        width: var(--container-width-lg);
        /* background-color: aquamarine; */
        height: 100%;    
        gap: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .info_top {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    .info_head {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: flex-start;
    }
    


    .info_img{
        display: block;
        width: 100%;
    }
    
    .info_img img{
        width: inherit;
        border-radius: 12px;
    }

    .para_prime{
        padding: 0 0 1rem 0;
        font-size: 0.8rem;
    }

    .info_reverse{
        flex-direction: column-reverse !important;
    }

    .add_r_margin{
        margin: 0;
    }

    .heading_prime_2{
        margin-top: 1rem;
        font-size: 1.2rem;
    }

    .svg_container{
        width: 100%;
        margin: 2rem 0;
    }

    .heading_prime{
        font-size: 1.6rem;
    }

    /* Camp sites */
    .campsite_section {
        width: 100%;
        height: max-content;
        background-color: var(--color-primary-white);
    }
    
    .campsite_main {
        width: 100%;
        height: max-content;
        padding: 0;
        margin-top: 2rem;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 1rem;
    }

    .camp_card_container {
        min-width: 90%;
        height: fit-content;
        display: flex;
        flex-direction: column;
    }
    
    
    .site_card {
        min-width: 222px;
        height: 450px;
        background-color: var(--color-primary-white);
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
    }
    
    .camp_card_img {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* transition: var(--transition); */
        transition: transform 0.75s;
    
    }

    /* Photo Section */
    
.camp_photo_section {
    width: 90%;
    height: 100vh;
    background-color: var(--color-primary-white);
    margin: 0 auto;
}

.camp_photo_container {
    height: 100%;
}


.photo_sec_container {
    width: 100%;
    height: 90%;
    justify-self: start;
}

    .photo_sec_inner {
        width: 100%;
        height: 90%;
        display: flex;
        gap: 12px;
        flex-direction: column-reverse;
    }

    .photo_grid {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns:  32% 32% 32%;
        grid-template-rows: auto auto auto auto;
        gap: 10px;
        color: white;
    }

    .photo_banner{
        width: 100%;
    }


    /* Scroll */
    .carousel-nav-btn-div{
        display: none;
    }

}


