.camps_container{
    width: 100%;
    height: 100%;
}

.table_container{
    width: 100%;
    min-width: fit-content;
    height: 100%;
}

.widge{
    margin: 0.5rem;
    border-radius: 0.6rem;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 20px 0px rgba(85, 74, 74, 0.25);
    border: 1px solid rgba(128, 128, 128, 0.2);
}

.camp_table{
    width: auto;
    height: fit-content;
    background-color: white;
}


// table Css
table {
    width: 100%;
    height: fit-content;
    border-radius: 0.6rem 0.6rem 0 0 ;
    padding: 0.5rem;
    border-collapse: collapse;
    display: table;
    thead {
        font-size: 15px;
        height: 25px;
        background-color: rgb(244, 246, 248);
        border-radius: 0.6rem 0.6rem 0 0 ;

        th{
            padding: 18px 12px;
            // color: rgb(83, 100, 114);
            color: var(--color-primary-head);
            // width: max-content ;
            cursor: pointer;
        }
        th{
            &:nth-child(1){
                border-radius: 0.6rem 0 0 0;
            }
            &:nth-child(5){
                width: max-content;

            }
            &:nth-child(6){
                width: 150px;
                text-align: center;
            }
            &:nth-child(8){
                border-radius: 0 0.6rem 0 0;
                width: 150px;
                text-align: center;
            }
        }
    }
    tbody{
        tr{
            vertical-align: middle;
            td{
                vertical-align: middle;
                padding: 14px 18px;
                border-top: 1px dashed rgba(90, 90, 90, 0.2);
                font-size: 12px;
            }
            td{
                &:nth-child(8){
                    text-align: center;
                }
            }
            td{
                &:nth-child(2){
                    width: max-content;
                }
            }
        }
    }
}

.camp_name{
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        margin-right: 0.5rem;
    }
}

.active_status{
    background-color: #148d0960;
    padding: 4px 6px;
    border-radius: 6px;
    color: var(--color-primary-head);
    width: fit-content;
}

.deactive_status{
    background-color: #ff000060;
    padding: 4px 6px;
    border-radius: 6px;
    color: var(--color-primary-head);
    width: fit-content;
}

.tag_contain{
    display: flex;
    flex-wrap: wrap;
    .camp_tags{
        font-size: 10px;
        background-color: #9100ae60;
        padding: 4px 6px;
        border-radius: 6px;
        margin: 4px;
        color: var(--color-primary-head);
        width: fit-content;
    }
}

.camp_add_container{
    width: fit-content;
    display: flex;
    flex-direction: row;
    position: relative;
    .camp_add_btn{
       button{
            border: none;
            list-style: none;
            padding: 8px 10px;
            background-color: var(--color-button-1 );
            border-radius: 0.6rem;
            color: white;
            font-weight: 600;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            border: 1px solid transparent;
            &:hover{
                background-color: #ffff;
                border: 1px solid var(--color-button-1-hover);
                color: var(--color-button-1);
            }
        }
    }
}








