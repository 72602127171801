  .nav_containner{
    width: 100%;
    height: 70px;
    /* background: rgba(255, 255, 255, 0.1); */
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(15px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 7;
  }
  
  .nav_box{
    width: var(--container-width-lg);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .nav_logo{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: var(--transition);
  }

  .nav_logo:hover {
    cursor: pointer;
  }
  
  .nav_logo img{
    height: 50px;
    transition: var(--transition);
  }
  
  .nav_right_main{
    width: 65%;
    height: 2.3rem;
    border-radius: 10px;
  }
  
  .nav_items{
    display: flex;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
    height: 2.3rem;
    align-items: center;
  }
  
  .nav_item{
    height: 2.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    background-color: white;
    padding: 12px;
    border: none;
    outline: none;
    position: relative;
  }
  
  .nav_item > a{
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    padding: 2px;
    transition: var(--transition);
  }

  .nav_item > p {
    color: black;
    display: flex;
    align-self: center;
    justify-content: center;
    padding-left: 3px;

  }
  
  .nav_first{
    border-radius: 10px 0 0 10px;
  }
  
  .nav_last{
    border-radius: 0 10px 10px 0;
  }
  
  /* Hover effect on nav items */
  
  .nav_item:hover{
    box-sizing: border-box;
    background-color: var(--color-hover-gray);
    border-bottom: 2px solid var(--color-primary);
    padding-bottom: 10px;
  }
  
  
  /*  dropdown */
  
  .dropdown_items {
    width : max-content;
    top: 37px;
    position: absolute;
    list-style: none;
    text-align: start;
    background: var(--color-primary-white);
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-top: 2px solid var(--color-secondary);
    transition: var(--transition);
    
  }
  
  .dropdown_container li {
    padding: 7px;
    margin-right: 0.3rem;
    cursor: pointer;
    /* transition: var(--transition); */
  }
  
  .nav_drop_item{
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: var(--color-dropdown);
    padding: 8px 11px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: var(--transition);
  }
  
  
  .nav_drop_first{
    border-radius: 10px 10px 0 0;
  }
  
  .nav_drop_last{
    border-radius: 0 0 10px 10px;
  }
  
  .nav_drop_item a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: var(--color-dropdown);
  }
  
  .nav_drop_item:hover{
    background-color: var(--color-hover-gray);
  }
  
  .dropdown_items.clicked {
    display: none;
  }
  
  .love a {
    color: var(--color-pink) !important;
  }

  @media screen and (max-width : 1175px) {

    .nav_right_main {
      display: none;
    }
    
  }

  @media screen and (max-width : 600px) {

  }





  /* Bottom navbar */
.bottom_nav_container{
  width: 95%;
  height: 7vh;
  /* background-color: #d3d3d3; */

  /* background-color: #335495; */


  background-color:  #ff9044;
  position: fixed;
  bottom: 0;
  z-index: 999;
  left: 2.5%;
  bottom: 0.7rem;
  border-radius: 0.5rem;

  /* box-shadow: 3px 6px 6px rgba(0, 0, 0, 0.6); */

  -webkit-box-shadow: 0px -1px 11px 0px rgba(0,0,0,0.45);
  -moz-box-shadow: 0px -1px 11px 0px rgba(0,0,0,0.45);
  box-shadow: 0px -1px 11px 0px rgba(0,0,0,0.45);

}

.bottom_nav_container>ul {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  color: #fff;
  align-items: center;
  justify-content: space-around;
  font-size: 2rem;
}

.navbottom_icon_container{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width : 600px) {

  .bottom_nav_container{
    display: none;
  }  
}


/* Active navbar */

.active p {
  color: #244789;
}
  