.card {
  width: 55vw;
  height: 70vh;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.card img {
  position: absolute; 
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  object-fit : cover !important;
  border-radius: 10px;
}

@media screen and (max-width : 600px) {
.card {
  width: 52vw;
  height: 50vh;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.card img {
  position: absolute; 
  top: 50%;
  left: 50%; 
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  object-fit : cover !important;
  border-radius: 10px;
}


}
  