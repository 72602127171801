/* -----Scrolling------ */
.right_dash::-webkit-scrollbar {
    background-color: #fff;
    opacity: 0.2;
    width: 16px;
}

.right_dash::-webkit-scrollbar-track {
    background-color: #fff;
    opacity: 0.2;
}

.right_dash::-webkit-scrollbar-thumb {
    background-color: #244789;
    opacity: 0.2;
    border-radius: 16px;
    border: 4px solid #fff;
}

.dashboard_container{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
    font-family: "Public Sans", sans-serif;
}

.dashboard_container>div{
    background-color: rgb(255, 255, 255);
    width: 95vw;
    height : 95vh;
    border-radius: 1rem;
    /* opacity: 0.9; */
    overflow: hidden;
}

.dashboard{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    display: grid;
    grid-template-columns: 180px auto;
}



.left_nav{
    width: 100%;
    height: 100%;
    border-right: 1px dashed rgba(145, 158, 171, 0.5);
    border-radius: 1rem;
}

.right_dash{
    width: auto;
    height: inherit;
    scroll-behavior: smooth;
    overflow-y: scroll;
}

.dash_logo{
    width: 100%;
    height: 70px;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dash_logo:hover{
    cursor: pointer;
}

.dash_logo>img{
    width: 170px;
    padding: 0.7rem 0.5rem 0.5rem;
    text-align: center;
}


.left_link {
    list-style: none;
    margin: 0px;
    padding: 0 16px;
    position: relative;
    min-height: fit-content;
    width: 100%;
}

.left_link>h5{
    box-sizing: border-box;
    list-style: none;
    padding: 18px 16px 8px;
    font-weight: 700;
    line-height: 1.5;
    font-size: 11px;
    text-transform: uppercase;
    font-family: "Public Sans", sans-serif;
    color: rgb(99, 115, 129);
}

.left_link>nav{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    list-style: none;
    color: rgb(99, 115, 129);
    font-size: 16px;
}

.nav_link{
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px 0px 4px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    min-width: 0px;
    box-sizing: border-box;
    text-align: left;
    padding: 8px 8px 8px 16px;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: relative;
    text-transform: capitalize;
    color: rgba(99, 115, 129, 0.9);
    border-radius: 8px;
    height: 48px;
    /* background-color: rgba(0, 171, 85, 0.08); */
    border-left: 3px solid transparent;
    transition: 0.2s all ease-in-out;
}

.nav_link:hover{
    background-color: rgba(0, 171, 85, 0.1);
}

.nav_link.active{
    background-color: rgba(0, 171, 85, 0.1);
    border-left: 3px solid var(--color-primary);
    color: var(--color-primary-head);
}

.nav_link>p{
    font-size: 26px;
    margin-right: 8px;
}


/* ------ Header Nav -------- */

.dash_heading{
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--color-primary-head);
    padding-left: 0.7rem;
    border-left: 4px solid var(--color-primary);
    display: flex;
}

.dash_heading span{
    display: flex;
    align-self: center;
    color: var(--color-primary);
}

.right_dash{
    width: inherit;
    display: flex;
    flex-direction: column;
    border-radius: 0 1rem 1rem 0 ;
}

.header_toolbar{
    width: calc(95% - 180px) ;
    padding: 1rem 2rem;
    max-width:inherit;
    background-image: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    flex-shrink: 0;
    position: fixed;
    top: inherit;
    left: inherit;
    right: inherit;
    /* color: rgb(255, 255, 255); */
    box-shadow: none;
    height: 80px;
    z-index: 8;
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.8);
    transition: height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 0 1rem 0 0 ;
}

.main_dash_container{
    border-radius: 0 1rem 1rem 0;
    padding: 90px 16px 16px 16px;
    width: 100%;
    height: inherit;
}

.tool_div button{
    margin: 0 0.6rem;
    transition: all 3ms ease-in-out;
}

/* Main Data Dashboard */
.dataDashboard_container {
    width: 100%;
    height: 100%;
}

.dashboard_card_container{
    width: 100%;
    height: fit-content;
    background-color: rgb(244, 246, 248);
    border-radius: 1rem;
    padding: 0.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap : 0.5rem;
}

.info_cards{
    position: relative;
    height: 6rem;
    width: 100%;
    border-radius: 1rem;
    padding: 0.8rem;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.card_icon{
    font-size: 2rem;
    align-self: flex-start;
    color:  rgb(255, 255, 255);
}

.info_cards:hover{
    cursor: pointer;
    scale: 1.02;
}

.info_cards::after{
    content: "";
    position: absolute;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #ffffff49;
    bottom: 0.5rem;
    right:  0.5rem;
}

.info_cards>div>h1,h2{
    color: #fff;
}

.info_cards>div>h1{
    font-size: 2rem;
}

.info_cards>div>h2{
    font-size: 0.9rem;
}


.card_1{
    background: rgb(255,87,10);
    background: linear-gradient(38deg, rgba(255,87,10,1) 0%, rgba(253,119,29,1) 51%, rgba(252,148,69,1) 100%);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 20px 0px rgba(85, 74, 74, 0.25);
}

.card_2 {
    background: rgb(35,161,205);
    background: linear-gradient(22deg, rgba(35,161,205,1) 0%, rgba(4,115,215,1) 54%, rgba(2,116,255,1) 100%);
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 20px 0px rgba(85, 74, 74, 0.25);
}

.card_3 {
    background: rgb(40,99,14);
background: linear-gradient(38deg, rgba(40,99,14,1) 0%, rgba(51,139,31,1) 51%, rgba(24,175,0,1) 100%);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 20px 0px rgba(85, 74, 74, 0.25);
}

.card_4 {
    background: rgb(131,0,25);
    background: linear-gradient(22deg, rgba(131,0,25,1) 0%, rgba(194,0,65,1) 54%, rgba(240,20,20,1) 100%);
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 20px 0px rgba(85, 74, 74, 0.25);
}


@media screen and (max-width: 600px) {
    .dashboard{
         width: 100%;
         height: 100%;
         border-radius: 1rem;
         display: flex;
         flex-direction: column;
         padding: 0;
         position: relative;

     } 
     

.left_nav{
    width: 100%;
    height: fit-content;
    border-bottom: 3px dashed rgba(145, 158, 171, 0.5) !important;
    border-radius: 1rem;
}



.dash_logo{
    width: 100%;
    height: 70px;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dash_logo:hover{
    cursor: pointer;
}

.dash_logo>img{
    width: 170px;
    padding: 0.7rem 0.5rem 0.5rem;
    text-align: center;
}


.left_link {
    list-style: none;
    margin: 0px;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    position: normal;
    min-height: fit-content;
    width: 100%;
}

.left_link>h5{
    box-sizing: border-box;
    list-style: none;
    padding: 18px 16px 8px;
    font-weight: 700;
    line-height: 1.5;
    font-size: 11px;
    text-transform: uppercase;
    font-family: "Public Sans", sans-serif;
    color: rgb(99, 115, 129);
}

.left_link>nav{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    color: rgb(99, 115, 129);
    font-size: 16px;
}

.nav_link{
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px 0px 4px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    min-width: 0px;
    box-sizing: border-box;
    text-align: left;
    padding: 8px 8px 8px 16px;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: relative;
    text-transform: capitalize;
    color: rgba(99, 115, 129, 0.9);
    border-radius: 8px;
    height: 48px;
    /* background-color: rgba(0, 171, 85, 0.08); */
    border-left: 3px solid transparent;
    transition: 0.2s all ease-in-out;
}

.nav_link:hover{
    background-color: rgba(0, 171, 85, 0.1);
}

.nav_link.active{
    background-color: rgba(0, 171, 85, 0.1);
    border-left: 3px solid var(--color-primary);
    color: var(--color-primary-head);
}

.nav_link>p{
    font-size: 26px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* ------ Header Nav -------- */

.dash_heading{
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-primary-head);
    padding-left: 0.7rem;
    border-left: 4px solid var(--color-primary);
    display: flex;
    align-self: flex-start;
    justify-content: center;
}

.dash_heading span{
    display: flex;
    align-self: center;
    color: var(--color-primary);
}


.right_dash{
    width: 100% !important;
    height: 100% !important;
    scroll-behavior: smooth;
    overflow-y: scroll;
    padding-right: 0 !important;
}

.header_toolbar{
    width: 95vw ;
    padding: 1rem;
    max-width:none;
    background-image: none;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: normal;
    box-sizing: border-box;
    flex-shrink: 0;
    position: fixed;
    top: inherit;
    left: inherit;
    right: inherit;
    /* color: rgb(255, 255, 255); */
    box-shadow: none;
    height: fit-content;
    z-index: 8;
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.8);
    transition: height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 1rem 1rem 0 0 ;
    gap: 1rem;
}

.tool_div{
    align-self: flex-end;
    background-color: #cddfff;
    padding: 0.5rem;
    border-radius: 0.5rem;
}


.tool_div button{
    margin: 0;
    transition: all 3ms ease-in-out;
    padding-right: 6px;
    padding-left: 10px;
}

.main_dash_container{
    border-radius: 0 1rem 1rem 0;
    padding: 120px 16px 16px 16px;
    width: 100%;
    height: auto;
}
 }